<template>
  <div>
    <div class="flex wrap">
      <div :class="`col-${col}`" v-for="(item,idx) in option" :key="'option-'+idx" style="margin:6px 0">
        <div class="flex-align unselect" @click="clicked(item)">
          <div :style="checkStyle(item)">
            <i class="material-icons" style="font-size:18px;color:white" v-if="checked(item)">check</i>
          </div>
          <div class="pc body3 sub">{{ item.label }}</div>
          <div class="mobile body4 sub">{{ item.label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CheckboxGroup",
    props: {
      value: {
        type: Array
      },
      option: {
        type: Array,
        required: true
      },
      col: {
        type: Number,
        default: 4
      }
    },
    created() {

    },
    data() {
      return {
      }
    },
    methods: {
      checkStyle(item) {
        let checked = this.checked(item);
        let deco = {
          border: '1px solid #dddddd',
          width: '20px',
          height: '20px',
          borderRadius: '4px',
          marginRight: '8px'
        };
        if(checked) {
          deco.border = '1px solid #ff6600';
          deco.backgroundColor = '#ff6600';
        }
        return deco;
      },
      checked(item) {
        return this.value.indexOf(item.value) > -1;
      },
      clicked(item) {
        let value = this.value;
        if(this.checked(item)) {
          this.value.remove(`this.value.findIndex( i => { return i === item.value})`);
        } else {
          value.push(item.value);
        }
        this.$emit('update:value', value);
      }
    }
  }
</script>

<style lang="stylus" scoped>

</style>
